import 'animate.css';

// Animate progressbars

const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const animateCSS = (element, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd, {once: true});
  });

window.addEventListener('DOMContentLoaded', () => {

  // Scroll in to view content
  const links = document.querySelectorAll('a[href*="#"]:not([href="#"])');
  
  links.forEach(link => {
    link.addEventListener('click', function(event) {
      const pathname = location.pathname.replace(/^\//, '');
      const linkPathname = this.pathname.replace(/^\//, '');
      const hostname = location.hostname;

      if (pathname === linkPathname && hostname === this.hostname) {
        const hash = this.hash;
        const target = document.querySelector(hash) || document.querySelector(`[name="${hash.slice(1)}"]`);
        
        if (target) {
          event.preventDefault();
          
          const navbarHeight = 67; // adjust this value according to your needs
          const targetOffsetTop = target.offsetTop - navbarHeight;
          
          window.scrollTo({
            top: targetOffsetTop,
            behavior: 'smooth'
          });
        }
      }
    });
  });

  // Progress barzzz
  const animateProgresBars = () => {
    const progresBars = document.querySelectorAll('.progressBar');
    progresBars.forEach((bar) => {
      if(isInViewport(bar)){
        const progress = bar.dataset.progress;
        bar.style.width = `${progress}%`;
      }
    });
  }
  window.addEventListener('resize', animateProgresBars);
  window.addEventListener('scroll', animateProgresBars);

  // Projects
  let project = {
    show: function(caller){
      const id = caller.dataset.id;

      fetch('/api/project/'+id).then(
        function(response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }

          // Examine the text in the response
          response.json().then(function(data) {
            let projectData = data[0];
            console.log(projectData);

            // Fill highlighted project
            document.querySelector('#highlighted-title').innerHTML = projectData.title;
            document.querySelector('#highlighted-description').innerHTML = projectData.description;
            document.querySelector('#highlighted-photo').src = projectData.image;
            document.querySelector('#highlighted-link').href = projectData.link;

            // Animate transition
            animateCSS('#projectsList', 'bounceOutRight').then(() => {
              document.querySelector('#projectsList').style.display = 'none';
              animateCSS('#highlighted-project', 'bounceInLeft');
              document.querySelector('#highlighted-project').style.display = '';
            });
          });
        }
      )
        .catch(function(err) {
          console.log('Fetch Error :-S', err);
      });
    },

    hide: function(){
      
      animateCSS('#highlighted-project', 'bounceOutRight').then( () => {
        document.querySelector('#highlighted-project').style.display = 'none';
        animateCSS('#projectsList', 'bounceInLeft')
        document.querySelector('#projectsList').style.display = '';
      });
    }
  }

  // Attach listener to all project items
  const projectItem = document.querySelectorAll('.project-item');
  projectItem.forEach(projectItem => {
    projectItem.addEventListener('click', function(e){
      e.preventDefault();
      project.show(this);
    });
  });

  // Attach listener to close project
  const projectClose = document.querySelector('#project-close');
  if(projectClose){
    projectClose.addEventListener('click', function(e){
      e.preventDefault();
      project.hide();
    });
  }

  document.body.addEventListener('click', function(e){
    const projectHighlightVisible = document.querySelector('#highlighted-project').style.display != 'none'
    // Hide project highlight when user clicked outside the project box and project box was visible
    if(!e.target.closest('#highlighted-project') && projectHighlightVisible) {
      project.hide();
    }
  });

});



const menuButton = document.querySelector('#toggle')
const mainMenu = document.querySelector('#mainMenu');

// Small menu toggle
menuButton.addEventListener('click', () => {
  if (mainMenu.classList.contains('on')) {
		mainMenu.classList.remove('on');
    mainMenu.classList.add('off');
	} else {
    mainMenu.classList.remove('off');
    mainMenu.classList.add('on');
  }
});

// Close menu when clicking anywhere else than the menu
document.addEventListener('click', (e) => {
  if(e.target.closest('#mainMenu'))
    return;

  if (mainMenu.classList.contains('on')) {
		mainMenu.classList.toggle('on');
    mainMenu.classList.toggle('off');
	};
});

// Assign active menu tab
const urls = {
  homePage: "/",
  aboutPage: "/over"
}
const path = window.location.pathname;

for(const key in urls){
  if (urls[key] == path ){
    document.querySelector('#'+key).classList.add('active');
  }
}


// My age
if(document.getElementById('leeftijd') != null) {
	const birthDay = 23, birthMonth = 0
	const time = new Date(), year = time.getFullYear(), month = time.getMonth(), day = time.getDate();
	const leeftijd = year-1998;

	if(month>birthMonth || month==birthMonth && day>=birthDay) {
		document.getElementById('leeftijd').innerHTML = leeftijd;
	} else {
		document.getElementById('leeftijd').innerHTML = leeftijd-1;
	}

	if(month==birthMonth && day==birthDay){
		// ToDo make it's my birthday message!
		console.log('Whoo Hoo! It\'s my '+ leeftijd + 'th birthday!!!');
	}
}


// Konami code listener
let kkeys = []
const konami = "ArrowUp,ArrowUp,ArrowDown,ArrowDown,ArrowLeft,ArrowRight,ArrowLeft,ArrowRight,KeyB,KeyA";

document.addEventListener('keydown', (e) => {
  kkeys.push( e.code );
	if ( kkeys.toString().indexOf( konami ) >= 0 ){
    kkeys = [];
		window.alert("I appears that you've enterd the Konami code, but nothing happend. maybe try something for the elitists");
	}
});

//1337 (leet) code listener
let lkeys = []
const leet = "Digit1,Digit3,Digit3,Digit7";

document.addEventListener('keydown', (e) => {
  lkeys.push( e.code );
	if ( lkeys.toString().indexOf( leet ) >= 0 ){
    lkeys = [];
		window.location.href="/1337";
	}
});
